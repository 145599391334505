export const GuideLinks = ({links = {}}) => {
  const {
    website,
    twitter,
    discord,
    telegram,
  } = links;

  const classNames = "w-[28px] h-[28px] lg:w-auto lg:h-auto";

  return (
    <div className="flex items-center gap-6">
      { website && (
        <a target="_blank" href={website}>
          <svg className="fill-white w-[24px] h-[24px] lg:w-auto lg:h-auto" width="26" height="26" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
          </svg>
        </a>
      )}
      { twitter && (
        <a target="_blank" href={twitter}>
          <svg className={classNames} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4231 6H25.8173L18.4038 14.4712L27.125 26H20.2981L14.9471 19.0096L8.83173 26H5.43269L13.3606 16.9375L5 6H12L16.8317 12.3894L22.4231 6ZM21.2308 23.9712H23.1106L10.976 7.92308H8.95673L21.2308 23.9712Z" fill="white"/>
          </svg>
        </a>
      )}
      { discord && (
        <a target="_blank" href={discord}>
          <svg className={classNames} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3333 23L9.99998 25.6667C8.30165 24.959 6.54298 24.2513 4.67331 23.0333C4.02131 22.6085 3.62598 21.8867 3.61031 21.1087C3.52898 17.1147 4.46598 13.0993 6.83848 8.854C7.14898 8.29833 7.66631 7.891 8.26631 7.678C9.69765 7.17 10.6693 6.77733 12.3333 6.5L13.3333 8.33333C13.3333 8.33333 14.3333 8 16 8C17.6666 8 18.6666 8.33333 18.6666 8.33333L19.6666 6.5C21.3306 6.77733 22.3023 7.17 23.7336 7.678C24.3336 7.891 24.851 8.29833 25.1615 8.854C27.534 13.0993 28.471 17.1147 28.3898 21.1087C28.374 21.8867 27.9786 22.6085 27.3266 23.0333C25.457 24.2513 23.6985 24.959 22 25.6667L20.6666 23M9.33331 21.6667C9.33331 21.6667 12.6666 23.3333 16 23.3333C19.3333 23.3333 22.6666 21.6667 22.6666 21.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.8332 19.3334C13.0298 19.3334 13.9998 18.2141 13.9998 16.8334C13.9998 15.4527 13.0298 14.3334 11.8332 14.3334C10.6366 14.3334 9.6665 15.4527 9.6665 16.8334C9.6665 18.2141 10.6366 19.3334 11.8332 19.3334Z" fill="white"/>
            <path d="M20.1667 19.3334C21.3633 19.3334 22.3333 18.2141 22.3333 16.8334C22.3333 15.4527 21.3633 14.3334 20.1667 14.3334C18.97 14.3334 18 15.4527 18 16.8334C18 18.2141 18.97 19.3334 20.1667 19.3334Z" fill="white"/>
          </svg>
        </a>
      )}
      { telegram && (
        <a target="_blank" href={telegram}>
          <svg className={classNames} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.84548 14.6887C3.84548 14.6887 14.7551 10.225 18.5388 8.65334C19.9891 8.02467 24.908 6.01284 24.908 6.01284C24.908 6.01284 27.1782 5.13284 26.989 7.27017C26.9258 8.15034 26.4213 11.2308 25.9168 14.5628C25.1602 19.278 24.3403 24.433 24.3403 24.433C24.3403 24.433 24.2143 25.879 23.1423 26.1305C22.0701 26.382 20.3045 25.2503 19.9891 24.9988C19.737 24.8102 15.2596 21.9812 13.6201 20.5982C13.1786 20.221 12.6741 19.4665 13.6831 18.5863C15.9533 16.5118 18.665 13.9342 20.3045 12.2997C21.0611 11.5452 21.818 9.78484 18.665 11.9223C14.1876 15.0028 9.77332 17.8948 9.77332 17.8948C9.77332 17.8948 8.76448 18.5235 6.87265 17.9577C4.98081 17.3918 2.77365 16.6375 2.77365 16.6375C2.77365 16.6375 1.26031 15.6943 3.84581 14.6887H3.84548Z" stroke="white" strokeWidth="1.5"/>
          </svg>  
        </a>
      )}
    </div>
  );
};